const transformFileForApi = (value: Blob | Record<string, unknown> | undefined) => {
  if (value instanceof Blob){
    return value;
  }
  
  if (value === null) {
    return null;
  }
  
  return undefined;
};

export default transformFileForApi;
