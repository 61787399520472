import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/getPaginated';
import { AxiosResponse } from 'axios';
import type { PaginatedRequest } from '@/application/api/getPaginated';
import type { PlacementDocumentTemplate, PlacementDocumentTemplateIndex, CreateRequest, UpdateRequest } from '@/modules/placement/types';
import transformFileForApi from '@/application/api/util/transformFileForApi';

export const index = (data: PaginatedRequest) => getPaginated<PlacementDocumentTemplateIndex[]>('placement-document-template', data);

export const show = (templateId: number) => get<AxiosResponse<PlacementDocumentTemplate>>(`placement-document-template/${templateId}`);

export const create = (template: CreateRequest) => post('placement-document-template', template);

export const update = (template: UpdateRequest) => put(`placement-document-template/${template.id}`, {
  ...template,
  // @TODO Handle sending images to API via Axios instance
  signatureImage: transformFileForApi(template.signatureImage),
});
