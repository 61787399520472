import { placementDocumentTemplateUserType } from '@/application/enums/placementDocumentTemplateUserType';
import { placementDocumentTemplateCandidateType } from '@/application/enums/placementDocumentTemplateCandidateType';

interface Tag {
  type: string;
  tag: string;
  i18n: string;
  userTypes?: number[];
  candidateTypes?: number[];
}

const tags: Tag[] = [
  {
    type: 'contract',
    tag: 'contract_startdatum',
    i18n: 'startDate',
  },
  {
    type: 'contract',
    tag: 'contract_einddatum',
    i18n: 'endDate',
  },
  {
    type: 'contract',
    tag: 'contract_uren',
    i18n: 'hours',
  },
  {
    type: 'contract',
    tag: 'contract_urenperiode',
    i18n: 'hoursPeriod',
  },
  {
    type: 'contract',
    tag: 'contract_zzpbtwkeuze',
    i18n: 'zzpVAT',
  },
  {
    type: 'contract',
    tag: 'contract_zzpbemiddelingspercentage',
    i18n: 'zzpMediationPercentage',
  },
  {
    type: 'contract',
    tag: 'contract_zzpvoorfinanciering',
    i18n: 'zzpPreFunding',
  },
  {
    type: 'placing',
    tag: 'plaatsing_functie',
    i18n: 'position',
  },
  {
    type: 'placing',
    tag: 'plaatsing_startdatum',
    i18n: 'startDate',
  },
  {
    type: 'placing',
    tag: 'plaatsing_einddatum',
    i18n: 'endDate',
  },
  {
    type: 'placing',
    tag: 'plaatsing_functieschaal',
    i18n: 'positionWage',
  },
  {
    type: 'placing',
    tag: 'plaatsing_periodiek',
    i18n: 'periodicity',
  },
  {
    type: 'placing',
    tag: 'plaatsing_inkooptarief',
    i18n: 'purchaseRate',
    userTypes: [placementDocumentTemplateUserType.EMPLOYER],
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
  {
    type: 'placing',
    tag: 'plaatsing_brutouurloon',
    i18n: 'hourlyWageUZK',
    candidateTypes: [placementDocumentTemplateCandidateType.TEMPORARY_OR_SECONDMENT],
  },
  {
    type: 'placing',
    tag: 'plaatsing_uurtariefzzpexbtw',
    i18n: 'hourlyWageZZP',
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
  {
    type: 'placing',
    tag: 'plaatsing_vastereiskosten',
    i18n: 'fixedTravelExpense',
  },
  {
    type: 'placing',
    tag: 'plaatsing_standplaatsnaam',
    i18n: 'locationName',
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
  {
    type: 'placing',
    tag: 'plaatsing_standplaatsadres',
    i18n: 'locationAddress',
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
  {
    type: 'placing',
    tag: 'plaatsing_standplaatspostcode',
    i18n: 'locationZipCode',
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
  {
    type: 'placing',
    tag: 'plaatsing_standplaatswoonplaats',
    i18n: 'locationCity',
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
  {
    type: 'placing',
    tag: 'plaatsing_woonwerkvergoeding',
    i18n: 'travelAllowance',
    candidateTypes: [placementDocumentTemplateCandidateType.TEMPORARY_OR_SECONDMENT],
  },
  {
    type: 'placing',
    tag: 'plaatsing_contactpersoon',
    i18n: 'contactPerson',
  },
  {
    type: 'placing',
    tag: 'plaatsing_contactpersoon_functie',
    i18n: 'contactPersonPosition',
  },
  {
    type: 'placing',
    tag: 'plaatsing_urenperweek',
    i18n: 'weeklyHours',
  },
  {
    type: 'placing',
    tag: 'plaatsing_ortregelingconformcao',
    i18n: 'premiumAccordingToCLA',
    candidateTypes: [placementDocumentTemplateCandidateType.TEMPORARY_OR_SECONDMENT],
  },
  {
    type: 'placing',
    tag: 'plaatsing_toeslag_regeling_inkoop',
    i18n: 'premiumAgreementPurchase',
  },
  {
    type: 'placing',
    tag: 'plaatsing_toeslag_regeling_verkoop',
    i18n: 'premiumAgreementSales',
    userTypes: [placementDocumentTemplateUserType.EMPLOYER],
  },
  {
    type: 'placing',
    tag: 'plaatsing_verkooptarief',
    i18n: 'customerRate',
    userTypes: [placementDocumentTemplateUserType.EMPLOYER],
  },
  {
    type: 'placing',
    tag: 'plaatsing_marge',
    i18n: 'customerMargin',
    userTypes: [placementDocumentTemplateUserType.EMPLOYER],
  },
  {
    type: 'placing',
    tag: 'plaatsing_minimumuur',
    i18n: 'minHours',
    candidateTypes: [placementDocumentTemplateCandidateType.TEMPORARY_OR_SECONDMENT],
  },
  {
    type: 'placing',
    tag: 'plaatsing_maximumuur',
    i18n: 'maxHours',
    candidateTypes: [placementDocumentTemplateCandidateType.TEMPORARY_OR_SECONDMENT],
  },
  {
    type: 'employer',
    tag: 'werkgever_naam',
    i18n: 'name',
  },
  {
    type: 'employer',
    tag: 'werkgever_adres',
    i18n: 'address',
  },
  {
    type: 'employer',
    tag: 'werkgever_postcode',
    i18n: 'zipCode',
  },
  {
    type: 'employer',
    tag: 'werkgever_woonplaats',
    i18n: 'residence',
  },
  {
    type: 'employer',
    tag: 'werkgever_telefoonnummer',
    i18n: 'phone',
  },
  {
    type: 'employer',
    tag: 'werkgever_contactpersoonintermediair',
    i18n: 'contactIntermediary',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_voornaam',
    i18n: 'firstName',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_achternaam',
    i18n: 'lastName',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_adres',
    i18n: 'address',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_postcode',
    i18n: 'zipCode',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_woonplaats',
    i18n: 'residence',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_functies',
    i18n: 'function',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_bedrijfsnaam',
    i18n: 'companyName',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_kvk',
    i18n: 'cocNumber',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_emailadres',
    i18n: 'mail',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_telefoonnumer',
    i18n: 'phone',
  },
  {
    type: 'candidate',
    tag: 'kandidaat_geboortedatum',
    i18n: 'birthday',
    candidateTypes: [placementDocumentTemplateCandidateType.SELF_EMPLOYED],
  },
];

export default tags;
